<template>
  <v-flex fill-height>
    <v-content class="fill-height">
      <v-layout
        row
        wrap
        align-center
        justify-center
        fill-height>
        <v-flex style="max-width: 400px;">
          <v-card
            light
            class="elevation-20">
            <v-card-text class="text-xs-center pa-4">
              <h1 class="display-1 text-xs-center text-uppercase font-weight-bold pt-4"><span class="red--text">Sharper </span><span class="grey--text">Arrow</span></h1>
              <h2 class="headline mt-2 grey--text text--darken-2">Beta Coming Soon</h2>
              <p class="subheading mb-3 text--darken-2">Subscribe to stay updated</p>
              <v-divider class="mb-3 mx-5" />
              <template v-if="signUpResult === null">
                <v-form
                  ref="form"
                  v-model="formValid"
                  lazy-validation>
                  <v-text-field
                    v-model="email"
                    label="Email address"
                    :disabled="submitting"
                    :loading="submitting"
                    :rules="emailRules"
                    name="EMAIL"
                    id="mce-EMAIL"
                    outline
                    required />
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input v-model="hiddenField" type="text" name="b_cfe8ecb159c9546d605967000_89f675a7bb" tabindex="-1"></div>
                  <v-btn
                    light
                    depressed
                    :disabled="!formValid"
                    :loading="submitting"
                    color="primary"
                    type="submit"
                    @click.prevent="submit()">Subscribe</v-btn>
                    <p class="caption grey--text mt-2">By clicking "Subscribe" you agree to our <a
                      href="/legal"
                      @click.prevent="$router.push('/legal')">Terms and Conditions</a></p>
                </v-form>
              </template>
              <template v-else>
                <v-alert
                  outline
                  :value="true"
                  :type="signUpResult.result === 'error' ? 'error' : 'info'"
                  class="text-xs-left">
                  <div v-html="signUpResult.msg" />
                  <v-btn
                    :color="signUpResult.result === 'error' ? 'error' : 'primary'"
                    outline
                    @click="signUpResult = null">Back</v-btn>
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-content>
  </v-flex>
</template>

<script>
import jsonp from 'jsonp'

export default {
  name: 'Home',
  data () {
    return {
      email: '',
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Must be a valid email'
      ],
      hiddenField: null,
      formValid: false,
      submitting: false,
      signUpResult: null
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.validate() && this.hiddenField === null) {
        this.submitting = true

        let data = {
          EMAIL: this.email,
          b_cfe8ecb159c9546d605967000_89f675a7bb: ''
        }

        let urlData = Object.keys(data).map((key) => {
          return key + '=' + encodeURIComponent(data[key])
        }).join('&')

        jsonp('https://sharperarrow.us19.list-manage.com/subscribe/post-json?u=cfe8ecb159c9546d605967000&id=89f675a7bb&' + urlData, { param: 'c' }, (error, data) => {
          this.$refs.form.reset()

          if (error) {
            console.error(error)
            this.signUpResult = {
              result: 'error',
              msg: 'Something went wrong, please try again later.'
            }
          }

          if (data) this.signUpResult = data

          this.submitting = false
        })
      }
    }
  }
}
</script>
